.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: rem(20);
  padding: 0 rem(8) 0 rem(15);
  row-gap: rem(4);
  contain: strict;
  height: rem(60);

  @media bp(lg) {
    contain: content;
    border-radius: 0 0 rem(8) rem(8);
    padding: rem(13) rem(20) rem(15) rem(20);
  }
}

.name {
  flex: 1 1;
  gap: rem(4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  font-size: rem(16);
  line-height: rem(18);
  font-weight: bold;
  min-width: 0;

  .nameWrapper {
    min-width: 0;

    @mixin textEllipsis;
  }

  .nameText {
    max-height: rem(18);

    @mixin textEllipsis;
  }

  @media bp(lg) {
    font-size: rem(24);
    line-height: rem(32);
    font-weight: bold;
    align-items: center;
    flex-direction: row;

    .nameText {
      max-height: rem(64);

      @mixin lineClamp override-2;
    }
  }
}

.info {
  display: none;

  @media bp(lg) {
    display: block;
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(20);
  }

  @mixin textEllipsis;
}

.scoreLine {
  color: clr(p-70);
  flex-shrink: 0;

  @media bp(lg) {
    border: bdr(1, w/10);
    color: clr(p-70);
    border-radius: rem(2);
    padding: rem(5) rem(9);
    background-color: clr(g-7);
  }
}

.button {
  width: rem(42);
  height: rem(38);
  transform: translateY(rem(-2));

  @media bp(lg) {
    height: rem(36);
    width: auto;
  }
}

.buttonText {
  display: none;

  @media bp(lg) {
    display: inline;
  }
}

.buttonIcon {
  flex-shrink: 0;
  width: rem(19);
  height: rem(16);
  padding: unset;

  @media bp(lg) {
    display: none;
  }
}

.buttonInner {
  padding: unset;

  @media bp(lg) {
    padding: 0 rem(20);
  }
}
