.container {
  contain: strict;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  background: linear-gradient(90deg, clr(cg-7) 0%, clr(cg-5) 100%);
  font-weight: bold;
  display: flex;
  flex-direction: column;

  @mixin transition opacity;

  @media bp(xl) {
    contain: layout paint;
  }
}

.results {
  flex: 1;
}

.resultsHeading {
  color: clr(cg-25);
  font-size: rem(20);
  line-height: rem(28);
  display: flex;
  gap: rem(10);

  @media bp(xl) {
    grid-column: span 3;
  }
}

.resultsQuery {
  color: clr(cw);
}

.resultsContainer {
  padding: rem(30) rem(15) rem(20) rem(15);
  color: clr(cg-60);
  display: grid;
  row-gap: rem(20);
  overflow-y: auto;
  max-height: calc(100vh - rem(40) - rem(50));

  @media bp(xl) {
    padding: 0 rem(15) 0 rem(20);
    max-height: calc(100vh - rem(215));
    margin: rem(35) 0 rem(50) 0;
    border-left: rem(1) solid clr(cg-10);
    grid-template-columns: repeq(3);
    column-gap: rem(30);
  }
}

.resultsLink {
  font-size: rem(14);
  line-height: rem(20);

  @media bp(xl) {
    display: grid;
    grid-template-columns: rem(80) 1fr;
    column-gap: rem(10);
  }
}

.resultsSpan {
  color: clr(cw);
}

.resultsLinkImage {
  display: none;

  @media bp(xl) {
    display: block;
  }
}

.allResultsLink {
  display: block;
  color: clr(cw);
  background-color: clr(cb);
  padding: 0 rem(15);
  z-index: 5;
  position: relative;

  &::after {
    position: absolute;
    pointer-events: none;
    display: block;
    left: 0;
    right: 0;
    bottom: 100%;
    height: rem(120);
    content: '';
    background: linear-gradient(transparent, clr(cg-7/50));
  }

  @media bp(xl) {
    &::after {
      display: none;
    }
  }
}

.allResultsInner {
  height: rem(50);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media bp(xl) {
    height: rem(70);
    justify-content: end;
    gap: rem(5);
  }
}

.allResultsLinkIcon {
  width: rem(16);
  height: rem(21);
  fill: clr(p-70);
}
