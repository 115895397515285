.button {
  --primary-btn-color: var(--primary-color, clrraw(p-70));
  --secondary-btn-color: var(--secondary-color, clrraw(p-40));

  position: relative;
  text-decoration: none;
  user-select: none;

  .inner,
  &::before {
    border-radius: rem(4);
  }

  &::before {
    box-shadow: shadow(td, --secondary-btn-color/40);
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(-4);
    top: rem(4);
    background-color: clr(--secondary-btn-color);

    @mixin transition background-color, box-shadow;
  }

  &:hover {
    .inner {
      transform: translateY(rem(2));
      background-color: clr(--secondary-btn-color);
      box-shadow: none;
    }

    &::before {
      box-shadow: shadow(te, --secondary-btn-color/40);
      background-color: clr(--secondary-btn-color/80);
    }
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 rem(20);
  height: 100%;
  background-color: clr(--primary-btn-color);
  box-shadow: inset 0 rem(10) rem(10) clr(--secondary-btn-color/30);
  font-size: rem(16);
  color: clr(cw);
  font-weight: bold;
  transform: translateY(0);
  white-space: nowrap;

  @mixin transition background-color, box-shadow, transform;
}

.innerText {
  @mixin textEllipsis;
}
