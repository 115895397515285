.container {
  pointer-events: none;
  opacity: 0;
  transition: trn(opacity);
  background-color: clr(b/10);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  position: absolute;
}

.hoverContainer {
  --width: rem(340);
  --corner-offset: rem(10);

  padding-top: rem(40);
  position: absolute;
  top: calc(var(--top, 0) - rem(30));
  left: var(--corner-offset);
  width: calc(100vw - (var(--corner-offset) * 2));

  @media bp(sm) {
    --left-real: calc(var(--left, 0) - (var(--width) / 2));
    --left-calculated: min(
      max(var(--corner-offset), var(--left-real)),
      calc(100vw - (var(--width) + var(--corner-offset)))
    );

    width: var(--width);
    left: var(--left-calculated);
  }
}

.tooltip {
  padding: rem(5);
  position: relative;

  &,
  * {
    color: clr(cw) !important;
  }

  &,
  &::before {
    border: bdr(1, cg-10);
    background-color: clr(cg-7);
    border-radius: rem(3);
  }

  &::before {
    clip-path: polygon(0 0, 100% 0, 0 100%);
    content: '';
    display: block;
    position: absolute;
    top: rem(-8);
    width: rem(16);
    height: rem(16);
    transform: rotate(45deg);

    --angle-offset: calc(var(--left, 0) - rem(8));

    left: calc(var(--angle-offset) - var(--corner-offset));

    @media bp(sm) {
      left: calc(var(--angle-offset) - var(--left-calculated));
    }
  }
}

.active {
  pointer-events: all;
  opacity: 1;
}

.head {
  border-radius: rem(2);
  background-color: clr(cg-10);
  font-size: rem(20);
  line-height: rem(28);
  padding: rem(5) rem(10);
}

.text {
  padding: rem(10) rem(10) rem(5) rem(10);
  font-size: rem(16);
  line-height: rem(25);
  display: flex;
  flex-direction: column;
  row-gap: rem(10);

  a {
    text-decoration: underline !important;
    text-decoration-thickness: rem(2) !important;
  }
}
