.container {
  contain: content;
  overflow-x: auto;
  overflow-y: hidden;
  min-height: rem(40);
  visibility: hidden;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: stretch;

  @mixin transition opacity, transform;

  @media bp(xl) {
    display: flex;
    justify-content: center;
  }
}

.sticky {
  position: sticky;
  top: 0;

  &.hasReadingProgress {
    top: rem(4);
  }

  &.dealbarAdmin {
    top: rem(72);

    @media bp(xl) {
      top: rem(92);
    }
  }
}

.containerVisible {
  visibility: visible;
}

.containerScrolled {
  opacity: 0;
  pointer-events: none;

  @media bp(xl) {
    pointer-events: all;
    opacity: 1;
  }
}

.iconCart {
  width: rem(16);
  height: rem(14);
}

.iconArrow {
  width: rem(7);
  height: rem(12);
  display: none;

  @mixin transition transform;

  @media bp(xl) {
    display: block;
  }
}

.link {
  background-color: clr(dealbar-bg);
  color: clr(cw);
  padding: 0 rem(15);
  box-shadow: shadow(tc, p-70/50);
  border-radius: 0 0 rem(4) rem(4);

  &::-webkit-scrollbar {
    display: none;
  }

  @media bp(xl) {
    min-width: auto;
  }
}

.linkContent {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: bold;
  gap: rem(10);
  padding: rem(8) 0 rem(6) 0;

  @mixin transition color;

  @media bp(xl) {
    padding: 0 rem(50);
    gap: rem(20);

    &:hover {
      color: clr(cw);

      .iconArrow {
        transform: translateX(rem(5));
      }
    }
  }
}

.linkLabel {
  font-size: rem(14);
  line-height: rem(16);

  @media bp(sm) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.iconCart,
.iconContainer,
.iconArrow {
  flex-shrink: 0;
}

.iconWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding-right: rem(15);

  &::after {
    content: '';
    display: block;
    right: 0;
    top: rem(5);
    bottom: rem(5);
    z-index: 100;
    width: rem(1);
    background-color: clr(cw/20);
    border-radius: rem(0.5);
    position: absolute;
  }

  .iconContainer {
    height: calc(100% - rem(10));
    width: rem(38);
    box-sizing: content-box;
    border-radius: 0;
  }
}

.icon {
  object-fit: contain;
  background-color: transparent;
  object-position: left center;
}

.bestDealsBar {
  position: fixed;
  top: rem(42);
  z-index: 100;
  width: calc(100vw - rem(4));
  left: rem(2);
  opacity: 0;
  pointer-events: none;
  transition: trn(opacity);

  @media bp(xl) {
    display: none !important;
  }
}

.bestDealsBarVisible {
  opacity: 1;
  pointer-events: all;
}

.hasReadingProgress {
  top: rem(46);
}
