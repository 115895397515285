.mobileNavButton {
  padding: rem(11);

  @media bp(xl) {
    display: none;
  }
}

.mobileNavIcon {
  width: rem(18);
  height: rem(18);
}

.popupClass {
  top: rem(40) !important;

  @media bp(xl) {
    top: rem(60) !important;
  }
}

.popupPreview {
  top: rem(72) !important;

  @media bp(xl) {
    top: rem(92) !important;
  }
}
