.container {
  contain: strict;
  height: rem(4);
  background-color: clr(g-90);

  @mixin transition transform;
}

.shuttle {
  transform: translateX(-100%);
  transform-origin: top left;
  height: 100%;
  width: 100%;
  background-color: clr(b/80);
}
