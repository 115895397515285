.container {
  font-size: rem(14);
  font-weight: 600;
  line-height: rem(20);
  letter-spacing: rem(-0.3);
  border-radius: rem(8);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: trn(background-color, border-color, color);
}

.disabled {
  pointer-events: none;
  background-color: clr(g-90) !important;
  border-color: clr(g-90) !important;
  color: clr(g-50) !important;
}

.primary {
  border: bdr(1, b/5);
  background-color: clr(g-7);
  color: clr(p-60);

  &:hover {
    background-color: clr(g-5);
  }
}

.secondary {
  border: bdr(1, p-60);
  background-color: clr(p-60);
  color: clr(b);

  &:hover {
    background-color: clr(p-40);
  }
}

.tertiary {
  border: bdr(1, b/5);
  background-color: clr(g-90);
  color: clr(b);

  &:hover {
    background-color: clr(g-70);
  }
}

.outline {
  border: bdr(1, b/10);
  color: clr(b);
  background-color: clr(w);

  &:hover {
    background-color: clr(g-90);
    border-color: clr(g-90);
  }
}

.ghost {
  border: bdr(1, transparent);
  background-color: clr(w);

  &:hover {
    background-color: clr(g-85);
  }
}

.danger {
  border: bdr(1, g-85);
  background-color: clr(w);
  color: clr(r-10);

  &:hover {
    background-color: clr(g-85);
  }
}

.sizeSmall {
  padding: rem(6);
}

.sizeMedium {
  padding: rem(8);
}

.sizeLarge {
  padding: rem(12);
}
