.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  z-index: 999999;
  background-color: clr(cb);

  @mixin transition opacity;
}

.transition {
  pointer-events: all;
  opacity: 0.6;
}
