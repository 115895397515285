.container {
  position: relative;
  z-index: 10;

  @media bp(xl) {
    order: 3;
  }
}

.buttonSearch {
  @media bp(xl) {
    border-top-right-radius: rem(2);
    border-bottom-right-radius: rem(2);
  }
}

.buttonFocused {
  background-color: clr(cg-60);
  color: clr(cb);

  &:hover {
    .icon {
      fill: clr(p-70);
    }
  }
}

@keyframes pending-search {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.form {
  display: flex;
  position: absolute;
  align-items: stretch;
  gap: rem(5);
  top: 0;
  left: 100%;
  width: calc(100vw - 100%);
  height: 100%;
  opacity: 0;
  pointer-events: none;
  color: clr(cb);

  @media bp(xl) {
    transition: trn(opacity, transform);
    width: rem(280);
    left: auto;
    right: 100%;
    transform: translateX(rem(40));
    gap: rem(10);
    flex-direction: row-reverse;
  }
}

.formFocused {
  opacity: 1;
  pointer-events: all;

  @media bp(xl) {
    transform: translateX(0);
  }
}

.buttonClose {
  @media bp(xl) {
    border-radius: rem(2);
  }
}

.input {
  flex: 1;
  background-color: clr(cg-60);
  padding: 0 rem(15);
  font-size: rem(13);
  color: clr(cb);

  @media bp(xl) {
    border-top-left-radius: rem(2);
    border-bottom-left-radius: rem(2);
  }
}

.pendingBar {
  display: none;
  pointer-events: none;
  opacity: 0;
  transition: opacity 100ms ease 0s;
  position: absolute;
  z-index: 3;
  overflow: hidden;

  &::after {
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    background-color: clr(p-70);
    animation-duration: 600ms;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

.formPending {
  .pendingBar {
    opacity: 1;
    display: block;
    height: rem(2);
    bottom: 0;
    left: rem(-40);
    width: calc(100% - rem(5));

    &::after {
      animation-name: pending-search;
    }

    @media bp(xl) {
      left: rem(50);
      width: calc(100% - rem(10));
      border-bottom-left-radius: rem(2);
      border-bottom-right-radius: rem(2);
    }
  }
}

.button {
  padding: rem(12);

  @mixin transition background-color;
}

.iconI {
  width: rem(16);
  height: rem(16);
}
