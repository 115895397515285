.container {
  position: relative;
  border-radius: rem(9999);
  border: rem(1) solid var(--border-color);
  background-color: var(--bg-color);
  transition: trn(background-color);

  --handle-shift: 0;
}

.sizeSmall {
  width: rem(32);
  height: rem(20);

  --handle-size: rem(16);
}

.on {
  &.sizeSmall {
    --handle-shift: rem(12);
  }
}

.handle {
  position: absolute;
  left: rem(1);
  top: rem(1);
  width: var(--handle-size);
  height: var(--handle-size);
  transition: trn(transform);
  transform: translateX(var(--handle-shift));

  --circle-outer-color: clr(g-90);
  --circle-inner-color: clr(w);
}

.variantGrey {
  --border-color: clr(g-85);
  --bg-color: clr(g-60);

  &:hover {
    --bg-color: clr(g-50);
  }
}

.variantPrimary {
  --border-color: clr(p-40);
  --bg-color: clr(p-70);

  &:hover {
    --bg-color: clr(p-60);
  }
}
