.icon {
  width: rem(12);
  height: rem(12);
  flex-shrink: 0;
}

.container {
  pointer-events: none;

  & > .icon {
    opacity: 0.3;
  }
}

.overlay {
  position: absolute;
}

.container,
.overlay {
  contain: strict;
  overflow: hidden;
  box-sizing: content-box;
  height: rem(12);
  width: rem(80);
  gap: rem(5);
  display: flex;
}
