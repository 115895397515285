.wrapper {
  contain: strict;
  overflow: hidden;
  position: fixed;
  z-index: 1000000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @mixin transition opacity;
}

.wrapperDark {
  background-color: clr(cb/30);
}

.wrapperLight {
  background-color: clr(cb/80);
}

.wrapperClosed {
  pointer-events: none;
  opacity: 0;
}

.html {
  overflow: hidden;
}
