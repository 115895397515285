.viafoura,
.vf3-comments {
  --background-color: clr(w) !important;
  --light-text-on-background-color: clr(b) !important;
  --dark-text-on-background-color: clr(b) !important;
  --light-text-on-default-color: clr(b) !important;
  --dark-text-on-default-color: clr(b) !important;
  --secondary-text-color: clr(b) !important;
  --text-on-accent-color: clr(b) !important;
  --default-color: clr(g-70);
  --primary-color-20: clr(g-70);
  --primary-color-10: clr(g-70);
  --primary-color-75: clr(b/75);
  --primary-color-100: clr(b);
  --text-on-primary-color: clr(w);
  --accent-color-light: clr(p-60) !important;
  --accent-color: clr(p-70) !important;
  --accent-color-dark: clr(p-40) !important;
}

/* fix for community tab buttons background (on the sidebar) */
.viafoura .vf-community-content .vf-tabbed-subnav .vf-tabbed-nav {
  background-color: clr(w) !important;
}

/* fix for community author names */
.viafoura .vf-secondary-text-colour {
  color: clr(g-10) !important;
}
