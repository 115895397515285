.container {
}

.loggedIn {
  position: relative;
}

.loggedInMenuButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.loggedInIcon {
  width: rem(32);
  height: rem(32);
}

.loggedInArrow {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: rem(10);
  height: rem(10);
  color: clr(g-25);
  transition: trn(transform);
}

.loggedInArrowOpened {
  transform: translateY(-50%) rotate(-180deg);
}

.loggedInMenu {
  background-color: clr(w);
  width: rem(190);
  right: 0;
  top: calc(100% + rem(10));
  position: absolute;
  transform: translateY(rem(-4));
  opacity: 0;
  transition: trn(transform, opacity);
  pointer-events: none;
}

.loggedInMenuOpened {
  pointer-events: all;
  transform: translateY(0);
  opacity: 1;
}

.loggedOutButton {
  height: rem(32) !important;

  @media bp(xl) {
    height: rem(36) !important;
  }
}
