.widget {
  contain: strict;
}

.button {
  position: relative;
  padding: rem(5) rem(10);
  display: flex;
  align-items: center;

  @media bp(xl) {
    padding: rem(8) 0;
  }
}

.icon {
  padding: rem(8);
  box-sizing: content-box;
  background-color: clr(b);
  border-radius: 100%;
  width: rem(14);
  height: rem(14);
  color: clr(w);

  @media bp(xl) {
    width: rem(18);
    height: rem(18);
  }
}

.count {
  position: absolute;
  background-color: clr(r-10);
  font-size: rem(5);
  height: rem(8);
  min-width: rem(8);
  border-radius: 100%;
  color: clr(cw);
  bottom: rem(8);
  right: rem(8);

  @media bp(xl) {
    bottom: rem(10);
    right: 0;
  }
}
