.container {
  display: flex;
  flex-direction: column;
  padding: rem(10);
  row-gap: rem(10);
  border-radius: rem(8);
  border: bdr(1, g-85);
  box-shadow: shadow(tg, b/10);
}

.button {
  justify-content: left !important;
  column-gap: rem(6);
  text-align: left;
}

.darkModeToggle {
  pointer-events: none;
}

.icon {
  width: rem(20);
  height: rem(20);
}

.buttonText {
  flex: 1;
}
