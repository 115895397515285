.container {
  overflow: hidden;
  contain: strict;
}

.image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.fill {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
