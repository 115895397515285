.container {
  contain: content;
  font-size: rem(16);
  min-height: rem(600);
  padding: rem(74) 0 rem(15) 0;
  background-image: linear-gradient(to bottom, clr(w), clr(g-90));

  @media bp(md) {
    min-height: rem(200);
    padding: rem(100) 0 rem(68) 0;
  }
}
